import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  browserService,
  CommonEvents,
  CommonEventTypes,
  DeviceInfoContext,
  FormAttemptContainer,
  FormAttemptsAdditionsProps,
  getSimpleEvent,
  handleOpenPopup,
  MAX_ATTEMPTS_DEFAULT,
  Nullable,
  PopupsEnum,
  pushDataLayer,
  useIntersectionObserver,
  useTheme,
} from '@lerna-core';
import { PageNameContext } from '@contexts';
import { getHeaderEventPositionByPageName } from './header.analytics';
import { HeaderContainerProps, HeaderLinkTypes } from './header.model';
import { ContactUsFormContainer } from '@features/common/forms/contactUsForm';
import { HeaderDesktopContainer } from './headerDesktop';
import { HeaderMobileContainer } from './headerMobile';

export const HeaderContainer = ({
  showSearch,
  hasSticky,
  translations,
  company,
  handleSticky,
  showFilters,
  handleOpenContactUs,
  handleErrorContactUs,
  handleSuccessContactUs,
  handleCloseContactUs,
  alwaysShowSearch = false,
  headerLinkType = HeaderLinkTypes.none,
  customerCoursesCount,
  useLoginText = false,
  withLogin = true,
  withMobileMenu = true,
  discountProgramLogo,
}: HeaderContainerProps): ReactElement => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const refHeader = useRef<Nullable<HTMLDivElement>>(null);

  const pageName = useContext(PageNameContext);
  const deviceInfoContext = useContext(DeviceInfoContext);

  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);
  const entryHeader = useIntersectionObserver(refHeader, {});

  const theme = useTheme();
  const dispatch = useDispatch();

  const headerIsSticky = hasSticky && isSticky;
  const showDesktopSearch = (showSearch && headerIsSticky) || alwaysShowSearch;

  const headerEventPosition = useMemo(
    () => getHeaderEventPositionByPageName(pageName),
    []
  );

  const handleOpenContactUsPopup = (): void => {
    dispatch(
      handleOpenPopup(PopupsEnum.contactUsFormPopup, headerEventPosition)
    );

    if (typeof handleOpenContactUs === 'function') {
      handleOpenContactUs();
    }
  };

  const handleCloseContactUsPopup = (): void => {
    if (typeof handleCloseContactUs === 'function') {
      handleCloseContactUs();
    }
  };

  const handleLogoClick = useCallback(() => {
    pushDataLayer(
      getSimpleEvent(
        CommonEvents.goToMainCoursesPageLogo,
        CommonEventTypes.overallEvents,
        headerEventPosition
      )
    );
  }, []);

  const getIconHelpColor = (headerIsSticky: boolean): string => {
    let color = theme.COLOR.TEXT_DARK_COLOR;
    if (headerIsSticky) {
      color = theme.COLOR.TEXT_LIGHT_COLOR;
    }

    return color;
  };

  useEffect(() => {
    if (entryHeader) {
      const isIntersecting = entryHeader?.isIntersecting;

      setIsSticky(!isIntersecting);

      if (typeof handleSticky === 'function') {
        handleSticky(!isIntersecting);
      }
    }
  }, [entryHeader]);

  return (
    <>
      <div ref={refHeader} />
      {isMobile ? (
        <HeaderMobileContainer
          data-instance="header"
          isSticky={headerIsSticky}
          showSearch={showSearch}
          showFilters={showFilters}
          hasSticky={hasSticky}
          handleOpenContactUsPopup={handleOpenContactUsPopup}
          iconHelpColor={getIconHelpColor(headerIsSticky)}
          translations={translations}
          company={company}
          isMobile={isMobile}
          headerLinkType={headerLinkType}
          withMobileMenu={withMobileMenu}
          handleLogoClick={handleLogoClick}
          discountProgramLogo={discountProgramLogo}
          withLogin={withLogin}
        />
      ) : (
        <HeaderDesktopContainer
          data-instance="header"
          isSticky={headerIsSticky}
          hasSticky={hasSticky}
          showFilters={showFilters}
          showSearch={showDesktopSearch}
          handleOpenContactUsPopup={handleOpenContactUsPopup}
          translations={translations}
          company={company}
          headerLinkType={headerLinkType}
          customerCoursesCount={customerCoursesCount}
          useLoginText={useLoginText}
          withLogin={withLogin}
          handleLogoClick={handleLogoClick}
          discountProgramLogo={discountProgramLogo}
        />
      )}
      <FormAttemptContainer
        FormElement={(props: {
          attemptsProps: FormAttemptsAdditionsProps;
        }): ReactElement => (
          <ContactUsFormContainer
            attemptsProps={{ ...props.attemptsProps }}
            handleClose={handleCloseContactUsPopup}
            handleOpen={handleOpenContactUs}
            handleSuccess={handleSuccessContactUs}
            handleError={handleErrorContactUs}
            translations={translations}
          />
        )}
        maxAttempts={MAX_ATTEMPTS_DEFAULT}
      />
    </>
  );
};
