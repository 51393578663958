import React, { ReactElement } from 'react';
import { FiltersShowAllContainerProps } from './filtersShowAll.model';
import { ArrowStyled, FiltersShowAllStyled } from './filtersShowAll.styled';
import { IconArrowLongComponent, useTheme } from '@lerna-core';
import { useCoursesPageContext } from '../../../coursesPage';

export const FiltersShowAllContainer = ({
  translations,
  handleClick,
}: FiltersShowAllContainerProps): ReactElement => {
  const theme = useTheme();

  const { discountProgram } = useCoursesPageContext();
  const customColor = discountProgram?.colors.filtersAccentColor;

  return (
    <FiltersShowAllStyled customColor={customColor} onClick={handleClick}>
      {translations?.show_all_filters}
      <ArrowStyled>
        <IconArrowLongComponent
          width={16}
          height={16}
          strokeColor={customColor || theme.COLOR.PRIMARY[500]}
        />
      </ArrowStyled>
    </FiltersShowAllStyled>
  );
};
